import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { FaCheckCircle as CheckIcon } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./pilot.module.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Pilot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
      otherTextFlag: false,
    }
  }

  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { data } = this.props
    const { submitted, submitting } = this.state

    return (
      <Layout>
        <SEO title="Pilot" />
        <div styleName="outer-wrapper">
          <Container styleName="container">
            <div styleName="inner-wrapper">
              <div styleName="desc-parent">
                <h1>Request a Pilot</h1>
                <p>
                  This questionnaire will help us understand your requirements
                  for piloting SHAPE and set up your account. It also includes
                  details of the SSA, who is the SHAPE Champion and will be
                  operating the pilot.
                </p>
                <p>
                  The SHAPE Pilot is available for 20 participants at one time.
                  You may also request a{" "}
                  <a styleName="demo-link" href="/demo">
                    demo account{" "}
                  </a>{" "}
                  for yourself.
                </p>
                <p style={{ marginBottom: 60 }}>
                  Success of the pilot depends on you. We're relying on you to
                  provide accurate details.
                </p>
              </div>
              <form
                styleName="form-parent"
                name="pilot"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="pilot-form"
              >
                <Row>
                  <Col sm={12}>
                    <div styleName="input-parent">
                      <label>Your full name</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-name"
                        name="Your full name"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div styleName="input-parent">
                      <label>Your company name</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-company"
                        name="Your company name"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div styleName="input-parent">
                      <label>Your email address</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-email"
                        name="Your email address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label>Your contact number</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-contact"
                        name="Your contact number"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Your office address
                        <br />
                        <span>
                          We require this to confirm your agreement to the{" "}
                          <a href="/pilotterms" target="_blank">
                            Pilot Terms of Use
                          </a>
                          .
                        </span>
                      </label>
                      <input
                        type="text"
                        required
                        id="pilot-input-officeaddress"
                        name="Your office address"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label>Total number of people in your organisation</label>
                      <input
                        type="text"
                        required
                        id="pilot-input-noofpeople"
                        name="Total number of people in your organisation"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        The survey period will be 8 working days. Please confirm
                        if this duration is sufficient for your organisation.
                        <br />
                        <span>
                          The survey period refers to the time from the start
                          date of the survey till the survey deadline.
                        </span>
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="Yes"
                            id="pilot-input-surveyperiod-yes"
                            required
                            name="The survey period will be 8 working days. Please confirm if this duration is sufficient for your organisation."
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="No"
                            id="pilot-input-surveyperiod-no"
                            required
                            name="The survey period will be 8 working days. Please confirm if this duration is sufficient for your organisation."
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Email of the person to be nominated as SSA
                        <br />
                        <span>
                          The SSA, or SHAPE Senior Administrator, is the SHAPE
                          Champion in your organisation. This is the person who
                          will work with us to set up SHAPE for your company and
                          run the coordination with us. This may be you, or a
                          colleague.
                        </span>
                      </label>
                      <input
                        type="text"
                        required
                        id="pilot-input-ssaemail"
                        name="Email of the person to be nominated as SSA"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Are your employees able to complete the survey in
                        English? <br />
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Yes"
                            id="pilot-input-englishsurvey-yes"
                            required
                            styleName="radio-input"
                            name="Are your employees able to complete the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            required
                            styleName="radio-input"
                            id="pilot-input-englishsurvey-no"
                            value="No"
                            name="Are your employees able to complete the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            value="Maybe"
                            id="pilot-input-englishsurvey-maybe"
                            required
                            styleName="radio-input"
                            name="Are your employees able to complete the survey in English?"
                            onChange={this.handleChange}
                          />{" "}
                          Maybe
                        </label>
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        You will need to nominate a management figurehead (e.g.,
                        CEO). Do you have such a figurehead for pilot purposes?
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            type="radio"
                            styleName="radio-input"
                            id="pilot-input-figurehead-yes"
                            name="You will need to nominate a management figurehead (e.g., CEO). Do you have such a figurehead for pilot purposes?"
                            required
                            value="Yes"
                            onChange={this.handleChange}
                          />{" "}
                          Yes
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            type="radio"
                            styleName="radio-input"
                            value="No"
                            required
                            id="pilot-input-figurehead-no"
                            name="You will need to nominate a management figurehead (e.g., CEO). Do you have such a figurehead for pilot purposes?"
                            onChange={this.handleChange}
                          />{" "}
                          No
                        </label>
                      </div>
                    </div>

                    {/* addintional question */}
                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Please select the current employee feedback mechanism
                        you have in place at your organisation.
                      </label>
                      <div>
                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="In-house survey"
                            id="pilot-input-feedback-inhouse"
                            required
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          In-house survey
                        </label>
                        <br />
                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="External (third-party) supplier"
                            required
                            id="pilot-input-feedback-external"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          External (third-party) supplier
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="Combination of internal and external"
                            required
                            id="pilot-input-feedback-both"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          Combination of internal and external
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="None"
                            required
                            id="pilot-input-feedback-none"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          None
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="Don’t know"
                            required
                            id="pilot-input-feedback-dontknow"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onChange={this.handleChange}
                            onClick={() =>
                              this.setState({ otherTextFlag: false })
                            }
                          />{" "}
                          Don’t know
                        </label>
                        <br />

                        <label className="form-group">
                          <input
                            styleName="radio-input"
                            type="radio"
                            value="Other"
                            required
                            id="pilot-input-feedback-other"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            onClick={() =>
                              this.setState({ otherTextFlag: true })
                            }
                          />{" "}
                          Other
                        </label>
                        {this.state.otherTextFlag && (
                          <input
                            type="text"
                            required
                            id="pilot-input-feedback-otherinput"
                            name=" Please select the current employee feedback mechanism
                            you have in place at your organisation"
                            className="form-control"
                            onChange={this.handleChange}
                          />
                        )}
                      </div>
                    </div>

                    <div styleName="input-parent">
                      <label styleName="customHeight">
                        Are you working with a SHAPE Distribution Partner, or
                        have you been referred by another company/person?
                        <br />
                        <span>
                          Knowing who you're working with lets us coordinate the
                          best experience for you.
                        </span>
                      </label>
                      <input
                        type="text"
                        id="pilot-input-distributor"
                        name="Are you working with a SHAPE Distribution Partner, or have you been referred by another company/person?"
                        className="form-control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div styleName="cta">
                  {submitted && (
                    <div styleName="submittedSuccess">
                      Thank you. We’ll be in touch very shortly.
                    </div>
                  )}
                  <p>
                    By submitting this questionnaire, I agree to the{" "}
                    <a id="pilot-terms-link" href="/pilotterms" target="_blank">
                      SHAPE Pilot Terms of Use
                    </a>
                  </p>
                  <button
                    id="pilot-form-submit-button"
                    type="submit"
                    disabled={submitted || submitting}
                    className="text-white"
                  >
                    {(submitted && "Submitted") ||
                      (submitting && "Submitting...") ||
                      "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  {
    allCountryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allPositionJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allIndustryJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allHowFindJson {
      edges {
        node {
          value
          name
        }
      }
    }
    allCompanySizeJson {
      edges {
        node {
          value
          name
        }
      }
    }
  }
`
